console.log('hello world');

setTimeout(navi, 10);

function navi() {
    var wrap = document.getElementsByClassName('mobile-navi');
    wrap = wrap.length && wrap[0] ? wrap[0] : null;
    if(wrap) {
        wrap.addEventListener("click", function() {
            var is_active = wrap.className.search('mobile-navi--active') !== -1;
            wrap.classList[is_active ? 'remove' : 'add']('mobile-navi--active');
        });
    }
}
